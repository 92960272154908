.js {

  .inview_animate {
    opacity: 0;
    animation-delay: $animation-time-fast;
    animation-duration: $animation-time-slow;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }

  .inview_animate_zoom {
    animation-delay: $animation-time-fast;
    animation-duration: 10s; // longest animation
    animation-fill-mode: forwards;
  }

  .inview_animate_in {
    animation-name: inviewAnimateIn;
  }

  .inview_animate_zoom_in {
    animation-name: inviewAnimateZoomIn;
  }

  @keyframes inviewAnimateIn {
    from {
      opacity: 0;
      transform: translate3d(0, 150px, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }


  @keyframes inviewAnimateZoomIn {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.15);
    }
  }
}