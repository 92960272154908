$header-height: 110px;
$header-height-sm: 70px;

$input-padding-y: 1rem;
$input-padding-x: .85rem;

@import "../../../../hfarm_corporate/javascript/src/stylesheets/hfarm_corporate_variables";
$danger: #ff5252;
$dark-gray: #232323;
$gray-200: #F4F2F2;
$gray-225: #f5f5f5;
$gray-250: #d7d7d7;
$gray-300: #e6e6e6;
$gray-400: #a6a6a6;
$gray-500: #979797;
$gray-600: #5f5f5f;
$gray-700: #303030;
$gray-800: #202020;
$gray-900: #111111;
$light-blue: #0385AB;
$orange: #e67300;

$dark: #000000;

$hfarm-courses-colors: (
  "dark": $dark,
  "gray-200": $gray-200,
  "gray-225": $gray-225,
  "gray-250": $gray-250,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
  "light-blue": $light-blue,
  "orange": $orange
);

$colors: map-merge($colors, $hfarm-courses-colors);
$theme-colors: map-merge(
  $theme-colors,
  $hfarm-courses-colors
);