// sign_in and sign_up pages
[registration-pages] {

  .hr-separator {
    width: 100%;
    height: 1px;
    background-color: $gray-600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-control,
  .custom-select {
    border: none;
  }
}
