
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-tile-component] {
  color: var(--theme-color);

  a:not(.btn) {
    color: var(--theme-color) !important;
  }

  .ar-16by10 {
    padding-bottom: 62.5%;
  }

  background-color: $gray-800;

  .course-tile__category {
    text-transform: uppercase;
  }


}
