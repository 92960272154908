
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-product-component] {

  [table-people-component] {
    // prevent missing table-people-component component class padding
    padding-left: 15px;
    padding-right: 15px;
  }

  [bookmark] .container {
    width: 100% !important;
  }

  .show-sidebar {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    z-index: 1;
    border-radius: 0;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  }

  .sidebar-container {
    position: fixed;
    width: 40%;
    top: 0;
    bottom: 0;
    left: 0;

    @include media-breakpoint-down(md) {
      width: 80%;
      overflow-y: auto;
    }
  }

  .sidebar-container, .course-product__sidebar {
    z-index: $zindex-youtube-popup;
  }

  .course-product__sidebar {
    background-color: rgba($gray-200, .5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

}
