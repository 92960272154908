
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[versus-component] {

  .small-avatar {
    width: 3rem;
    height: 3rem;
    top: calc(14.64% - 1.5rem);
    left: calc(14.64% - 1.5rem);
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    background-color: #{$user-badge-bg-color};

    .far {
      font-size: 1.5rem;
      color: #{$user-badge-color};
    }
  }

  .answer {
    cursor: pointer;
    position: relative;

    .answer-percentage {
      font-size: 1.5rem;
      color: $primary;
    }

    .svg-container svg circle {
      stroke: $primary;
      transition: all $animation-time-slow linear;
      stroke-dashoffset: 365; // svg circle circumference
      stroke-dasharray: 365;
    }

    .svg-container.fill svg circle {
      stroke: $gray-400;
      stroke-dashoffset: 730;
    }
  }

  @include media-breakpoint-down(xs) {
    .answer .answer-percentage {
      font-size: 2rem;
    }
  }
}
