
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[ai-speech] {
  .icon-container {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    border-radius: 50%;
  }

  .fa-microphone {
    cursor: pointer;
    
    &.is-recording {
      -webkit-text-stroke: .5px $primary;
      color: transparent;
    }
  }
}
