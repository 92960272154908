
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-chat-component] {
  --input-height: 3rem;
  --icon-height: 2rem;

  .container {
    max-width: 850px;
  }

  .conversation {
    height: calc(100dvh - var(--main-navbar-height));
    
    .conversation-inset {
      overflow: hidden;

      .conversation-body {
        overflow-y: auto;
      }

      .conversation-footer {
        .conversation-form {
          .form-group {
            position: relative;
            border-radius: 0.5rem;
            border: 1px solid #ced4da;
            display: flex;
            flex-direction: column;
            
            textarea {
              padding: 0.75rem 3rem;
              border-radius: 0.5rem;
              height: var(--input-height);
              resize: none;
              overflow: hidden;
              border: 0;
            }
          }

          .preview-image {
            width: 3.5rem;
            height: 3.5rem;
            border-radius: .5rem;
            margin: .5rem;
          }

          .conversation-action {
            position: absolute;
            bottom: 0.5rem;
            width: calc(var(--input-height) - 1rem);
            height: calc(var(--input-height) - 1rem);
            background-color: $gray-200;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.5rem;
            cursor: pointer;

            i {
              font-size: 1.25rem;
            }

            &.conversation-action-left {
              left: 0.5rem;
            }

            &.conversation-action-right {
              right: 0.5rem;
            }

            &.btn {
              outline: none !important;
              box-shadow: none !important;
              padding: 0;
              background-color: $dark;
              color: $light;
            }
          }
        } 

        .file {
          display: inline;
          background-color: $gray-200;
          padding: 0.5rem;
          border-radius: 0.5rem;
        }

        .conversation-tip {
          li {
            width: 50%;

            @include media-breakpoint-down(md) {
              width: 100% !important;
            }

            > div {
              border: 1px solid $gray-200;
              color: $gray-600;
              padding: 0.5rem;
              border-radius: 0.5rem;
              cursor: pointer;
            }      
          }
        }
      }

      .message {
        margin-top: 2rem;

        .icon {
          width: var(--icon-height);
          height: var(--icon-height);
          line-height: var(--icon-height);
          text-align: center;
          border-radius: 100%;
          margin-right: 1rem;

          i {
            position: relative;
            top: 1px;
          }
        }

        &.assistant {
          .icon {
            background-color: $primary;
            color: $white;
          }

          img {
            width: 300px;
            max-width: 100%;
            margin: 1.5rem 0 1.5rem 0;
            display: block;
            border-radius: 0.5rem;
            aspect-ratio: 1/1;
            object-fit: cover;
          }

          h1, h2, h3 { 
            margin-top: 1.5rem; 
            font-size: 1.5rem;
          }
        }

        &.user {
          .icon {
            background-color: $gray-200;
            color: $black;
          }
        }
      }
    }
  }
}
