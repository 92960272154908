
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[footer-component] {
  background-color: $gray-700;

  a[data-toggle="collapse"] {

    & > i {
      transition: transform .25s;
    }

    &.collapsed > i {
      transform: rotate(180deg);
    }
  }

  .btn {
    width: fit-content;
  }

  #supported-by {
    font-size: .75rem;
    font-weight: 800;
  }

  .social-icon {
    font-size: 1.25rem;
    color: $gray-200;
    transition: color .25s;

    &:hover,
    &:active,
    &:focus {
      color: $gray-300;
    }
  }

  .logo {
    max-width: 6rem;
  }

  .navbar-link {
    font-weight: 300;
    color: $white;
    transition: color $animation-time-fast;

    &:hover {
      color: darken($white, 15%);
    }
  }

  .navbar-link,
  .section-title {
    font-size: .875rem;
  }

  .section-title {
    font-weight: 800;
  }

  .disclaimer {
    font-size: 0.75rem;
    color: $gray-200;
  }

  .box-bg {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  @include media-breakpoint-down(sm) {
    .logo {
      background-size: 80% !important;

      &.background-left {
        background-position: left;
      }

      &.background-right {
        background-position: right;
      }
    }
  }

}

