html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  @include hidpi {
    @include anti-alias-font;
  }
  color: $gray-900 !important;
  
  padding-top: $header-height;
  @include media-breakpoint-down(md) {
    padding-top: $header-height-sm;
  }

  &.kaltura-hasOpenedFullScreen {
    padding-top: 0;

    [navbar-component] {
      display: none;
    }
  }
}

/* Flickity slider override */
.flickity-carousel .flickity-page-dots .dot {
  height: $flickity-dots-height;
  width: 30px;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 0;
}

.fplayer-overlay {
  display: none !important;
}

@include media-breakpoint-up(xl) {
	.w-limiter {
		max-width: 1920px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (min-height: 1080px) {
  .mh-100vh, 
	.mh-100vh-header {
		height: 1080px !important;
		min-height: auto !important;
	}
}

.ar-2-75by1 {
  padding-bottom: 36.363636%;

  @include media-breakpoint-down(sm) {
    padding-bottom: 56.25%;
  }
}

.btn {
  text-transform: uppercase;
}

.grecaptcha-badge { visibility: hidden; }

/* Components override */

[page-live-event-qa-component] {

  .btn {
    font-size: 1rem !important;
  }
}