
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[versus-widget-component] {
  .progress-bar-box {
    right: 0;
    bottom: 0;
    background-color: transparent;

    .progress-bar {
      background-color: #ced4da;
    }
  }

  .wait-message {
    background-color: $gray-300;
    color: $dark;
    z-index: 10;
  }

  .question-title {
    color: var(--theme-color);
    text-align: center
  }

  .answer {
    transition: all $animation-time-fast linear;
    border-radius: 0.625rem;
    position: relative;
    overflow: hidden;
    background-color: var(--theme-background);
    cursor: pointer;
    
    &__text {
      z-index: 1;
      overflow-wrap: break-word;
      overflow: hidden;
    }

    &__percentage {
      z-index: 1;
      max-width: 95px;
      text-align: right;
    }

    &:hover, &.choose {
      background-color: #6c757d;
      color: var(--theme-background);
    }

    // Poll - user answer 
    &.choose.no-status {
      .progress-bar-box {
        background-color: var(--player-grey);

        .progress-bar {
          background-color: #6c757d;
        }
      }
    }

    // Quiz
    &.correct {
      font-weight: 600;

      .progress-bar-box {
        background-color: $correct-color;

        .progress-bar {
          background-color: $correct-dark-color;
        }
      }

      &.choose {
        color: white !important;
      }
    }

    // Quiz - user answer
    &.choose.wrong {
      color: white !important;
      font-weight: 600;

      .progress-bar-box {
        background-color: $wrong-color;

        .progress-bar {
          background-color: $wrong-dark-color;
        }
      }
    }

    &.voting-closed, &.not-repeatable {
      cursor: auto;
      border: none !important;

      &:not(.choose):hover {
        color: var(--theme-color);
      }
    }
  }

  .word-cloud-container {
    height: 340px !important;
    background-color: var(--theme-background);
    border-radius: 12px;
  }

}
