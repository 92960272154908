
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[list-keyopints-tile-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  .keypoint-title {
    font-size: 1.875rem;
    font-weight: normal;
  }

  @include media-breakpoint-up(md) {

    .keypoint-text {
      max-width: 80%;
    }
  }

}

