
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  


[course-countdown-component] {

	.course-countdown-undo {
		color: $dark;
	}

  .svg-container {

    .countdown {
      position: relative;
      height: 70px;
      width: 70px;
      text-align: center;
    }

    .countdown-number {
      color: $dark;
      font-size: 1rem;
      font-weight: bold;
      z-index: 1;
    }

   	.svg-container svg circle {
      stroke: $dark;
      transition: all 1s linear;
      stroke-dashoffset: 352; // svg circle circumference
      stroke-dasharray: 352;
    }

    .svg-container.fill svg circle {
      stroke: $gray-400;
      stroke-dashoffset: 705 // svg circle circumference * 2;
    }
  }
}  
