
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[navbar-component] {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  height: $header-height;

  @include media-breakpoint-down(md) {
    height: $header-height-sm;
  }

  .navbar-toggler {
    z-index: 1050;
    width: 3rem;
    text-align: center;
  }

  .navbar__support-content {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding-top: $header-height-sm;
  }

  .navbar-logo {
    height: 2.8rem;
    width: auto;
  }

  .secondary-navbar-logo {
    width: 2.7rem;
    height: auto;
  }

  .search-icon {
    transition: color .25s;
    font-size: 1.25rem;
    color: white;

    &:hover {
      color: darken($white, 10);
    }
  }

  .search-bar {
    z-index: 1051;

    // .search-icon {
    //   color: $dark;
    // }

    input {
      height: auto !important;
      padding: .5rem 1rem;
      background-color: $gray-900;
      border: 0;  
    }
  }

  .nav-item {
    text-transform: uppercase;
    font-size: .75rem !important;
    letter-spacing: 1px;

    a {
      color: $gray-300;
      text-decoration: none;
      transition: color .25s;
      font-weight: bold;
    }

    &:hover,
    &:active,
    &:focus,
    &.active {
      a {
        color: $white;
      }
    }
  }
}
