
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-search-component] {  
  .heading {
    background-color: $gray-200;
  }

  .search-heading {
    font-size: 1.5rem;
  }

  .results-count {
    color: $gray-400;
    font-size: $font-size-xs;
    text-transform: uppercase;
  }

  .deco {
    font-size: $font-size-xs;
    text-transform: uppercase;
    color: $primary;
    font-weight: 400;
  }

  .child:hover {
    .child-title {
      color: $gray-400;
    }
  }

  .child-title {
    font-size: 1.25rem;
    transition: color .25s;
  }

  .custom-input {
    height: 3.75rem;
    margin-left: 0;
    padding: 0;
    font-size: 2.25rem;
    line-height: 2.875em;
    text-transform: uppercase;
    font-weight: 600;
    color: $dark;
    background-color: transparent;
    border: 0;
    outline-width: 0px;

    &::placeholder {
      color: $gray-400;
    }
  }

  .thumbnail {
    height: 6.25rem;
    width: 9.375rem;
  }

  .breadcrumb {
    *, .breadcrumb-item > a, .breadcrumb-item + .breadcrumb-item::before {
      color: $dark !important;
      font-weight: 700;
      opacity: .5;
    }

    .breadcrumb-item:last-child {
      font-weight: 700;
      color: $dark !important;
    }
  }
}
