
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-product-trivia-carousel-component] {

  .trivia-carousel-item {
    min-height: calc(75vw * 0.56);

    @include media-breakpoint-down(sm) {
      min-height: calc(100vh - #{$header-height-sm});
    }
  }

  .trivia-carousel__feedback {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    pointer-events: none;
    z-index: 1020;

    > div {
      color: $white;
      padding: 0.5rem 2rem;
      border-radius: 20px;
    }
  }

  .trivia-carousel__progress {
    top: 0;
    left: 0;
    z-index: 1;
    height: 2rem;
  }

  .trivia-question {
    &.big-fonts {
      font-size: 1.6rem;
    }
  }

  .navigation__next, .navigation__prev {
    align-items: center;
    background-color: #dcdcdcbb;
    border-radius: 50%;
    border: solid 1px $dark;;
    display: flex;
    height: 3.2rem;
    justify-content: center;
    opacity: 0.6;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.6s;
    width: 3.2rem;
    z-index: 5;

    &:not(.disabled) {
     &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }

    .trivia-carousel__navigation-icon {
      font-size: 1.25rem;
      color: $dark;
    }
  }

  .navigation__next {
    right: 3rem;
  }

  .navigation__prev {
    left: 3rem;
  }

  .btn-course-trivia {
    position: relative;
    text-transform: uppercase;
    border-radius: 0.5rem;
    width: 28rem;
    font-size: 0.85rem;
    overflow: hidden;

    &.big-fonts {
      font-size: 1.2rem;
    }
  }

  .correct-answer {
    background-color: $green;
  }

  .wrong-answer {
    background-color: $red;
  }

  @include media-breakpoint-down(sm) {
    
    .trivia-carousel__progress {
      height: 3rem;
    }

    .btn-course-trivia {
       width: 100%;
    }

    .navigation__next, .navigation__prev {
      top: 2.5rem;
      height: 2.8rem;
      width: 2.8rem;

      .trivia-carousel__navigation-icon {
        font-size: 1rem;
      }
    }

    .navigation__next {
      right: 2rem;
    }

    .navigation__prev {
      left: 2rem;
    }

  }

}
