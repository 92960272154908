
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[points-marker-component] {
  border-radius: 2rem;
  
  h6 { 
    font-size: 1.25rem;
    margin-bottom: 0;
  }

  .internal-marker {
    background-color: $yellow;
    font-size: 0.85rem;
    padding: 0.25rem 1rem;
    border-radius: 2rem;
  }

  .done {
    background-color: $success;
    color: $white;
  }
}
