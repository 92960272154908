
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-carousel-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  position: relative;

  .flickity-viewport:focus, .flickity-carousel:focus {
    outline: none !important;
  }

  .flickity-button.flickity-prev-next-button {
    z-index: 2;
  }

  .flickity-button.flickity-prev-next-button:disabled {
    display: none;
  }

  .flickity-button-icon {
    transform: scale(.65);
  }

  .flickity-carousel-item {
    padding-left: 1rem;
    padding-right: 0rem;

    > div {
      position: relative;
    }
  }

  .view-more-link {
    background-color: $gray-900;
    color: $gray-700;

    & > i {
      transition: transform;
      transition-duration: 3s;
      transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    &:hover > i {
      transform: scale(1.15);
    }
  }

  .stripe-carousel__skin-1 .flickity-carousel-item {
    width: 75%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    & ::v-deep .tile__decorator-icon {
      width: 12rem;
    }
  }

  .stripe-carousel__skin-2 .flickity-carousel-item {
    width: 20%;
  }

  .stripe-carousel__skin-3 .flickity-carousel-item {
    width: 30%;
  }

  /* side shadows */
  .flickity-viewport::after,
  .flickity-viewport::before {
    z-index: 1;
    opacity: 0;
    content: '';
    height: 100%;
    width: 5.25rem;
    position: absolute;
    pointer-events: none;
    transition: opacity .5s;
    background: transparent;
  }

  .flickity-carousel .flickity-viewport::after,
  .flickity-carousel:not(.hide-left-shadow) .flickity-viewport::before {
    opacity: 1;
  }

  .flickity-carousel .flickity-viewport::before {
    background: linear-gradient(to left, rgba(0, 0, 0, 0), $dark);
  }

  .flickity-carousel .flickity-viewport::after {
    right: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), $dark);
  }
  /* end side shadows */

  @include media-breakpoint-down(lg) {

    .stripe-carousel__skin-2 .flickity-carousel-item {
      width: 25%;
    }

    .stripe-carousel__skin-3 .flickity-carousel-item {
      width: 40%;
    }
  }

  @include media-breakpoint-down(sm) {

    .flickity-viewport::after,
    .flickity-viewport::before {
      width: 2rem;
    }

    .stripe-carousel__skin-1 .flickity-carousel-item {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      width: 90%;
    }

    .flickity-button.flickity-prev-next-button {
      display: none;
    }

    .stripe-carousel__skin-2 .flickity-carousel-item {
      width: 65%;
    }

    .stripe-carousel__skin-3 .flickity-carousel-item {
      // width: 75%;
      width: 85%;
    }

    .stripe-carousel__skin-2 .flickity-carousel-item,
    .stripe-carousel__skin-3 .flickity-carousel-item {
      & ::v-deep .tile__decorator-icon {
        width: 3rem;
      }
    }

    .stripe-carousel__skin-1 .flickity-carousel-item {
      & ::v-deep .tile__decorator-icon {
        width: 6rem;
      }
    }
  }
}
