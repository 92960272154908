
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[ranking-item-component] {
  a {
    color: $dark !important;
    text-decoration: none !important;
  }

  @include media-breakpoint-up(md) {
    .animate-zoom-md {
      transition: transform 0.75s linear;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .avatar-container {
    right: 0;
  }

  .line-clamp{
    display: -webkit-box; 
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical; 
    overflow: hidden;
  }

  .gradient {
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    height: 25%;
    background-image: linear-gradient(0deg, rgba(238, 238, 238, 0), rgba(0, 0, 0, 0.3));
    position: absolute;
  }

  .avatar {
    height: 2.8rem;
    width: 2.8rem;
    border-radius: 50%;
    z-index: 1;
    background-color: $primary;
  }

  .avatar-text {
    @extend .avatar;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
