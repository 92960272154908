p {
  & > a {
    font-weight: $font-weight-demi;
    transition: color $animation-time-fast;
  }
}

ul {
  padding-inline-start: 1rem;
}

h2, .h2, h3, .h3 {
  line-height: 1.4 !important;
}

@include media-breakpoint-down(sm) {

  h1, .h1 {
    font-size: $h1-font-size-sm;
  }

  h2, .h2 {
    font-size: $h2-font-size-sm;
  }

  .display-1 {
    font-size: $display1-size-sm;
  }

  .display-2 {
    font-size: $display2-size-sm;
  }
}

.font-weight-black {
  font-weight: 900;
}

.category {
  font-size: $font-size-sm;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.1px;
}

.description {
  h4, .h4, h3, .h3 {
    margin-bottom: .10rem !important;
    font-weight: 600;
  }
}