
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-product-video-chapter-trivia-interaction-component] {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
