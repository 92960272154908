
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.sticky-top {
  top: $header-height;

  @include media-breakpoint-down(sm) {
    top: $header-height-sm;
  }
}
