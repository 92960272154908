
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[interaction-trivia-component] {
  background-color: $trivia-component-bg-color;
  color: $trivia-component-color;
  overflow: scroll;
  max-height: 100vh;

  .btn-trivia-answer {
    background-color: transparent;
    color: $white;
    border: 1px solid $white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-height: 5rem;
    font-size: 0.85rem;

    @include media-breakpoint-down(sm) {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      min-height: 4rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    &.btn-light {
      background-color: $white;
      border-color: $white;
      color: $dark;
    }

    &.btn-danger {
      background-color: $danger;
      border-color: $danger;
    }

    &.btn-success {
      background-color: $success;
      border-color: $success;
    }
  }
}
