
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-product-component] {

  .course-sidebar__header {
    color: $white;
    border-bottom: 1px solid $dark;

    h3 {
      font-size: $font-size-base;
    }

    .course-sidebar__close {
      color: $white;
    }
  }

  .course-sidebar__item-icon {
    i { color: $white; }
  }

  .course-index__item {
    text-transform: none;
    color: $white;
    font-weight: bold;
    font-size: $font-size-base;

    &.collapsed .far.fa-chevron-up {
      transform: rotate(180deg);
    }

    &.secondary {
      font-weight: normal;
    }
  }

  .card {
    border-radius: 0;
    border-right: none;
    border-color: $dark;
  }

  .card-header {
    border-bottom: none;
    border-radius: 0 !important;
  }

  .card:not(:first-child) {
    border-top: none;
  }

}
