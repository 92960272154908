
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[breadcrumb-component] {
  padding-left: 2.5rem;
  background-color: transparent;

  .breadcrumb {
    text-transform: uppercase;
    font-size: .75rem;

    *, .breadcrumb-item > a, .breadcrumb-item + .breadcrumb-item::before {
      color: $dark !important;
      font-weight: 300;
    }

    .breadcrumb-item:last-child {
      font-weight: 600;
      color: $dark !important;
    }
  }
}
