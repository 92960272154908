
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  


[stripe-one-col-media-component] {
	position: relative;
	color: $white;

  .mh-base {
    min-height: 600px;
  }

	.icon {
    width: 18rem;
    max-width: 75%;
  }

  .progressive-gradient {
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(241,241,241,0) 100%);
  }
}
