// Bootstrap 
$primary: #e2231a;
$secondary: #909090;

$link-hover-decoration: none;

$gray-100: rgb(249, 249, 249);
$gray-200: #F4F2F2;
$gray-225: #f5f5f5;
$gray-250: #d7d7d7;
$gray-300: #e6e6e6;
$gray-400: #a6a6a6;
$gray-500: #979797;
$gray-600: #909090;
$gray-700: #5f5f5f;
$gray-800: #505050;
$gray-900: #303030;
$dark: #202020;

$hfarm-colors: (
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-225": $gray-225,
  "gray-250": $gray-250,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900
);

$zindex-youtube-popup: 1090;
$zindex-breadcrumb: 1010;

$font-size-base: 1rem;
$font-size-sm: .8rem;

$display1-size: 4rem;
$display2-size: 2.8rem;
$h1-font-size: 2rem;
$h2-font-size: 1.4rem;
$h3-font-size: 1.2rem;
$h4-font-size: 1rem;
$h5-font-size: .875rem;
$h6-font-size: .75rem;

$headings-margin-bottom: 1rem;

$display1-size-sm: $display1-size;
$display2-size-sm: $h1-font-size;
$h1-font-size-sm: $h1-font-size;
$h2-font-size-sm: $h2-font-size;

// Change the default font weight of bootstrap
$font-weight-base: 300;
$font-weight-demi: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$headings-font-weight: $font-weight-extra-bold;
$display2-weight: $font-weight-extra-bold;
$font-weight-bolder: $font-weight-extra-bold;

// Change the default font family of bootstrap
$font-family-base: "Maison Neue", sans-serif;

// Buttons
$btn-padding-y: 1rem;
$btn-padding-x: 3rem;
$btn-font-size: .7rem;
$btn-font-weight: $font-weight-demi;
$btn-border-radius: 3rem;
$input-btn-padding-y: 1rem;
$input-btn-padding-x: 0.75rem;

@import "~src/stylesheets/fandom_variables";
$colors: map-merge($colors, $hfarm-colors);
$theme-colors: map-merge($theme-colors, $hfarm-colors);

$colors: map-merge($colors, $hfarm-colors);
$theme-colors: map-merge($theme-colors, $hfarm-colors);

$btn-width: 10rem;
$header-height: 110px;
$header-height-sm: 70px;
$auxiliary-navbar-height: 64px;

$quote-color: #CCC;
$header-bg-dark: #1f1f1f; 

// font sizes

$font-size-lg: 3rem;
$font-size-md: 1.5rem;
$font-size-sm: 1rem;
$font-size-xs: .8125rem;

$padding-large: 6.5rem;
$padding-sm-large: 3.25rem;
$flickity-dots-height: 3px;

$grid-gap: 5px;