
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[password-visibility-switch-component] {
  .input-group-text {
    width: $input-height;
  }
}
