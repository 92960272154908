
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-article-podcast-component] {
  background-size: 100% auto;

  .content-container {
    min-height: 600px;

    iframe {
      .player_title {
        color: white !important;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .content-container {
      min-height: auto;
    }
  }

}
