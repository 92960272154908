
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  


.media {
  cursor: pointer;

  img {
    max-width: 200px;
  }

  .layer {
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@include media-breakpoint-down(sm) {
  .media {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
    }
  }
}
