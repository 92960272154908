
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[user-dashboard-component] {
  $title-contrast-size: .5px;
  $title-contrast-color: rgba($white, .75);

  .user-dashboard-content {
    height: $user-dashboard-height;
  }

  .z2 {
    z-index: 2;
  }

  .user-dashboard-avatar {
    width: 8rem;
    height: 8rem;
    font-size: 2rem;
    overflow: hidden;
    color: $user-dashboard-avatar-color;
    background-color: $user-dashboard-avatar-bg-color;

    .edit-icon {
      opacity: 0;
      transition: opacity .25s ease-in-out;

      &.has-avatar {
        background-color: rgba($black, .3);
      }
    }

    &:hover {
      .user-icon {
        opacity: 0;
      }

      .edit-icon {
        opacity: 1;
      }
    }
  }

  .user-icon {
    pointer-events: none;
  }
}
