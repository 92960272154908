
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.list-group {
  right: 0;
  max-width: 250px;
  min-width: 170px;
  position: absolute;
  bottom: 100%;
  background-color: rgba(0, 0, 0, .75);

  &-item {
    height: 2.2rem;
    background-color: transparent;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    &:hover {
      background-color: rgba(255,255,255,.1);
      color: $white;
    }

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 2.2rem;
    }

    &.active {
      border: none;
      background-color: transparent;
    }
  }
}
