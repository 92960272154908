
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-vertical-component] {

  .icon {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 1;
  }

  .bg {
    transition-duration: 3s;
    transition-timing-function: cubic-bezier(0.23,1,0.32,1);
  }
}
