
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[list-keypoint-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  img {
    height: 80px;
  }
}

