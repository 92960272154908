
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-live-event-meeting-settings-modal-component] {
  #localVideo {
    max-width: 100%;
    height: auto;
  }

  #oscilloscope {
    width: 100%;
    height: 3rem;
  }
}
