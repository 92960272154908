
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-product-ecommerce-component] {

	.product-section:nth-child(even) {
    background-color: $gray-200;
	}

  .product-section__r {
    color: var(--theme-color);

    a:not(.btn) {
      color: var(--theme-color) !important;
      text-decoration: underline;
    }
  }

  .bottom-bar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100vw;
    z-index: 1;

    color: var(--theme-color);
  }

  @include media-breakpoint-down(sm) {
    .btn {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}
