
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[chapters-list] {
  display: flex;
  flex-direction: column;

  .list-container {
    overflow-y: scroll;
    flex: 1;

    @include media-breakpoint-down(md) {
      overflow-y: visible;
    }
  }
}
.card {
  border: 0;
  cursor: pointer;

  &-body {
    display: flex;

    &__image {
      width: 7rem;
      min-width: 7rem;
      height: auto;
      overflow: hidden;

      @include media-breakpoint-down(sm) {
        width: 4rem;
        min-width: 4rem;
      }

      img {
        border-radius: .875rem;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      flex: 1;

      .card-title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media-breakpoint-down(sm) {
          font-size: .875rem !important;
          max-width: 75%;
        }
      }

      .playing-badge {
        width: fit-content;
        font-size: .5rem;
        font-weight: 600;
        padding: .1rem .25rem;
        color: $white;
        border-radius: .875rem;
        background-color: $player-red-color;
      }
    }
  }
}
