
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[miniformat-badge-component] {
  background-color: $primary;
  color: $white;
  font-size: 0.75rem;
}
