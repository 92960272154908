
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-live-event-survey-component] {
  .live-event-modal {
    .survey-modal__content {
      overflow-y: scroll;
      border-radius: 12px;
      background-color: $white;
      background-color: var(--theme-background);
      
      .title {
        width: 600px;
        max-width: 100%;
      }

      .fa-times {
        right: 0;
        top: 1.5rem;
        z-index: 1;
      }

      .flickity-slider {
        display: flex;
        align-items: center;

        @include ie {
          display: block;
        }
      }
    }
  }

  .survey__progress {
    height: 10px;
  } 

  [page-live-event-survey-item-component], .survey__progress {
    width: 600px;
    max-width: 100%;
  }
}
