
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-grid-component] {
  background-color: var(--theme-background);

  .btn-group .btn {
    border-radius: 0;
  }

  .navbar-toggler {
    font-size: .875rem;
    transition: transform .25s;
    height: 4rem;
    border: none;

    &:focus {
      outline: 0px;
    }
  }

  .collapsed .navbar-toggler {
    transform: rotate(180deg);
  }

  .navbar-brand {
    font-size: 1rem;
    font-weight: 600;
  }
}
