
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[modal-user-group-required-component] {

  [generic-form-component]::v-deep {
    .form-control {
      border: 1px solid $gray-400;
    }

    .form-group {
      text-align: left;
    }

    .progress {
      display: none;
    }
  }
}
