
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[countdown-component] {
  --stroke-dashoffset: 471;

  .countdown {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &__label {
      position: absolute;
      color: $white;
      font-weight: 600;
    }

    &__svg {
      border-radius: 100%;

      .circle {
        fill: transparent;
        transform: rotate(-90deg);
        transform-origin: center;
        stroke: var(--empty-color);
        stroke-dasharray: var(--stroke-dashoffset);
        stroke-dashoffset: var(--stroke-dashoffset);
      }

      @keyframes clock-animation {
        0% {
          stroke-dashoffset: var(--stroke-dashoffset);
        }
        100% {
          stroke-dashoffset: 0;
          background: var(--empty-color);
        }
      }
    }
  }
}
