$google-red: #EA4335;
$google-blue: #4285F4;
$google-green: #34A853;
$google-orange: #FBBC05;
$facebook-blue: #3b5998;

.facebook-svg-icon {
  fill: #FFF;
}

.google-svg-icon {
  fill: none;
  fill-rule: evenodd;
}

.gs-1 {
  fill: $google-blue;
}

.gs-2 {
  fill: $google-green;
}

.gs-3 {
  fill: $google-orange;
}

.gs-4 {
  fill: $google-red;
}

.btn-google {
  color: rgba($black, .54);
  border: 1px solid #ccc;
  background-color: $white;

  .btn-google_logo-container {
    border-radius: .25rem;
  }

  .google-svg-icon {
    border-radius: .25rem;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $google-blue;
    border-color: $google-blue;
    color: $white;

    .btn-google__logo-container {
      background-color: $white;
    }

    .google-svg-icon {
      background-color: $white;
    }

    .gs-1 {
      fill: darken($google-blue, 25%);
    }
  }
}

.btn-facebook,
.btn-google {
  padding-right: 0;
  width: 15.875rem;
  height: 2.5rem;
  font-family: Roboto, Arial, sans-serif;
  overflow: hidden;
  display: flex;
  border-radius: .25rem;
  text-decoration: none !important;

  .btn-facebook__logo-container {
    height: 1.5rem;
    margin: .5rem;
    width: 1.5rem;
    display: block;
  }

  .btn-google__logo-container {
    &:hover {
      background-color: $white;
    }
    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .btn-facebook__text-container,
  .btn-google__text-container {
    width: 13.4375rem;
    padding: .5rem;
    font-size: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.btn-facebook {
  fill: $white;

  &:hover,
  &:focus,
  &:active {
    fill: $facebook-blue;
  }
}


@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    &.correct-answer, &.wrong-answer {
      border: none;
      color: $white;
      &:hover {
        color: $white;
      }
    }

    &.wrong-answer {
      &:hover {
        box-shadow: none;
      }
    }

    &.correct-answer {
      box-shadow: 0px 0px 13px 2px rgba($value, 0.5);
    }

    &:hover {
      box-shadow: 0px 0px 13px 2px #{$value}80;
    }    

  }
}