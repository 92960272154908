
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

$stripe-search-autocomplete-item-thumbnail-size: 5rem;

[stripe-search-autocomplete-item-component] {
  .item-thumbnail {
    width: $stripe-search-autocomplete-item-thumbnail-size;
    height: $stripe-search-autocomplete-item-thumbnail-size;
    background-color: $gray-300;
  }
}
