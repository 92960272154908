.error-pages-title {
  font-size: 3rem;
  font-weight: bold;
}

.error-pages-description {
  font-size: 2rem;
  font-weight: 300;
}

.error-pages-title,
.error-pages-description {
  color: $white;
  text-shadow: 0 0 1px $black;
}

.buorg {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  border: 0;
  align-items: flex-end;
  justify-content: center;
  background-color: rgba($black, .35) !important;
  font-family: $font-family-base;
  pointer-events: none;
}

.buorg-pad {
  width: 100%;
  padding: 1rem !important;
  display: block;
  background-color: $white;
  pointer-events: all;
}

.buorg-icon,
.buorg-mainmsg {
  display: none !important;
}

.buorg-moremsg {
  display: inline-block;
  font-size: .875rem;
}

.buorg-buttons {
  display: inline-block !important;

  #buorgig {
    @extend .btn;
    padding: .45rem 1.25rem !important;
    font-size: .65rem !important;
  }

  #buorgul {
    display: none;
  }

  #buorgig {
    @extend .btn-secondary;
  }

}
