/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  margin-top: 100vh;
  background-color: transparent;

  -webkit-transition: all 0.35s ease-out;
  -moz-transition: all 0.35s ease-out;
  transition: all 0.35s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
  margin-top: 0;
  background-color: rgba($white, .8);
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
  margin-top: 100vh;
  background-color: transparent;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  margin-top: 100vh;
  background-color: transparent;

  -webkit-transition: all 0.35s ease-out;
  -moz-transition: all 0.35s ease-out;
  transition: all 0.35s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
  margin-top: 0;
  background-color: $white;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
  margin-top: 100vh;
  background-color: transparent;
}
