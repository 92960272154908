
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

$feedback-text-font-size: 1rem;
$feedback-icon-font-size: 1.5rem;
$feedback-width: 30rem;

[trivia-feedback-alert] {
  z-index: $zindex-feedback;
  width: $feedback-width;
  max-width: 100%;
  border-radius: 15rem;

  .feedback-text {
    font-size: $feedback-text-font-size;
    font-weight: normal;
  }

  i {
    font-size: $feedback-icon-font-size;
  }

  &.feedback-success {
    background-color: $success;
  }

  &.feedback-wrong {
    background-color: $danger;
  }
}
