
    @import "tenants/tenants-hfarm/hfarm_courses/javascript/src/stylesheets/hfarm_courses_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-component] {

  .tile-title {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tile__icons {

    .tile__video-icon,
    .tile__buy-icon {
      border-radius: 100%;
      border: 1px solid $white;
      width: 2.5rem;
      height: 2.5rem;
      line-height: 2.5;
      text-align: center;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .tile__video-icon {
      border: 1px solid $white;
      color: white;
    }

    .tile__buy-icon {
      color: $dark;
      background-color: $white;
    }
  }

  .tile__decorator-icon {
    width: 6rem;
  }

  .tile__category {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  .tile__btn-group {
    margin-left: -0.25rem;
    margin-right: -0.25rem;

    .btn {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }

  .tile__footer {
    padding: 1.75rem;

    .tile-footer-img-container img {
      width: 5rem;
    }
  }

  .bg {
    transition-duration: 3s;
    transition-timing-function: cubic-bezier(0.23,1,0.32,1);
  }

  @include media-breakpoint-down(sm) {

    .tile__button-group .btn {
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      margin-top: .5rem;
      margin-bottom: .5rem;
    }
  }
}
